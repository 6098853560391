.profile-image-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 14.30vw;
  height: 14.30vw;
  margin-left: 6.9444%;

  border-radius: 50%;

  /* Version 1 / Black */

  background: #234240;
  box-shadow: 0px 0px 30px rgba(35, 66, 64, 0.08);
}

.missing-pic-avatar {
  width: 4.7667vw;
  height: 5.48167vw;
}

.streamer-profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover; 

  border-radius: 50%;
}