.whole-screen-justify-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-popup-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;

  display: flex;
  justify-content: center;
  align-items: center;
  
  background: rgba(0,0,0,0.6);
}

.add-event-popup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 50vw;
  top: 20%;
  left: 25%;
  background: #FFFFFF;
  border-radius: 20px;
  
}

.add-event-title {
  margin-right: 19.8vw;

  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  
  text-align: center;
  letter-spacing: 0.01em;
    
  color: #234240;
}

.add-event-pop-up-exit-button {
  height: 1.1vw;
  width: 1.1vw;
  
  cursor: pointer;
}

.add-event-pop-up-exit-button:focus {
  outline: none !important;
  outline-offset: none !important;
}

.add-event-popup-info-container {
  display: flex;
  flex-direction: column;
  width: 91.891%;
  margin-top: 2%;
  background: #FFFFFF;

  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.add-event-popup-header{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  margin-top: 4.05%;
  width: 91.891%;
}

input, textarea { 
  height: 5.34vh;

  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  border: none;
  
  letter-spacing: 0.005em;
  color: #98A3A1;
}

input:focus, textarea:focus {
  outline: none !important;
  outline-offset: none !important;
}

input::placeholder, textarea::placeholder {
  color: #98A3A1;
}

.add-event-name-field{
  margin-left: 2.23%;
  margin-right: 2.23%;
}

.add-event-description-field {
  min-height: 21.4356vh;
  margin-left: 2.1%;
  margin-right: 2.23%;

  resize: none;
  text-justify: inherit;  
  word-wrap: normal;
}

.add-event-divider-horizontal {
  background: #E3ECEB;
  height: 0.22vh;
}


.multi-input-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.add-event-divider-vertical {
  background: #E3ECEB;
  height: 2.56vh;
  width: 0.22vh;
}

.field-static-text {

  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: right;
  letter-spacing: 0.005em;

  color: #234240;
}

.add-event-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  
  width: 91.891%;

}

.add-event-button {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 3vh;
  margin-bottom: 3vh;
  height: 5.246vh;
  width: 13.25vw;

  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: 1000;
  font-size: 20px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.01em;

  border-radius: 100px;
}

.bottom-row-input-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 45.6%; 
}

.date-picker {
  position: absolute;
  width: 46%;
  top: 72%;
  margin-top: -20px;
  border-radius: 20px;

  background: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.add-event-time-picker{
  display: flex;
  flex-direction: row;
  align-items: center;

  position: absolute;
  height: 12.42%;
  width: 46%;
  top: 72%;
  left: 50%;
  margin-top: -20px;
  border-radius: 10px;

  background: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.time-picker-form {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.time-picker-input-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex;
  text-align: center;

  height: 3.96vh;
  width: 3.96vh;
  background: #F0F7F6;
  border-radius: 10px;

  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.005em;

  color: #98A3A1;
}

.time-picker-input-container::placeholder {
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.005em;

  color: #98A3A1;
}

.time-picker-green-text {
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: 0.005em;

  /* Version 1 / Primary Color */

  color: #12908E;
}

.time-picker-gray-text {
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.005em;

  color: #98A3A1;
}
