.whole-screen-justify-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-popup-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;

  display: flex;
  justify-content: center;
  align-items: center;
  
  background: rgba(0,0,0,0.6);
}

.edit-profile-popup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 50vw;
  top: 5%;
  left: 25%;
  background: #FFFFFF;
  border-radius: 20px;
  
}

.edit-profile-title {
  margin-right: 20vw;

  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  
  text-align: center;
  letter-spacing: 0.01em;
    
  color: #234240;
}

.add-event-pop-up-exit-button {
  height: 1.1vw;
  width: 1.1vw;
  
  cursor: pointer;
}

.add-event-pop-up-exit-button:focus {
  outline: none !important;
  outline-offset: none !important;
}

.edit-profile-popup-info-container {
  display: flex;
  flex-direction: column;
  width: 91.891%;
  margin-top: 4%;
  background: #FFFFFF;

  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.add-event-popup-header{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  margin-top: 4.05%;
  width: 91.891%;
}

.edit-missing-pic-avatar {
  width: 2.2667vw;
  height: 2.867vw;
}

.edit-profile-image-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 6.94vw;
  height: 6.94vw;
  margin-top: 3.55%;
  margin-left: 1%;

  border-radius: 50%;

  background: #234240;
  box-shadow: 0px 0px 30px rgba(35, 66, 64, 0.08);
}

.upload-button-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 19.5%;
  margin-top: 1.625%;
  margin-left: 1%;

}

.edit-profile-upload-photo-button {
  width: 100%;
}