.whole-screen-justify-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-popup-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;

  display: flex;
  justify-content: center;
  align-items: center;
  
  background: rgba(0,0,0,0.6);
}

.event-popup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 42.251vw;
  top: 24%;
  left: 31.3%;
  background: #FFFFFF;
  border-radius: 20px;
  
}

.pop-up-exit-button {
  /* top: 5.5%;
  left: 94.2%; */
  height: 0.98vw;
  width: 0.972vw;
  margin-top: 4.05%;
  margin-left: 89.8%;
  
  background: transparent;
  /* border: none; */
  cursor: pointer;
}
.pop-up-exit-button:focus {
  outline: none !important;
  outline-offset: none !important;
}

.event-popup-info-container {
  width: 91.891%;
  margin-top: 4%;
  margin-bottom: 4%;

  background: #FFFFFF;

  border: 1.5px solid #E3ECEB;
  box-sizing: border-box;
  border-radius: 20px;
}

.checkout-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  height: 5vh;
  width: 91.891%;
  margin-bottom: 4.25%;
}

.checkout-button {
  height: 100.714%;
  cursor: pointer;
}

.event-popup-cancel-text {
  margin-right: 6%;

  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;

  text-align: center;
  letter-spacing: 0.01em;

  cursor: pointer;
  color: #12908E;
}

.event-title-plus-price {
  margin-top: 4.05%;
  margin-left: 4.05%;
  margin-right: 4.05%;
}

.event-popup-title-text {
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;

  letter-spacing: 0.01em;

  color: #234240;
}

.event-popup-price {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: -4.923%;
  
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: 1000;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.01em;
  
  color: #234240;
}

.event-description {
  margin-top: 4.05%;
  margin-left: 4.05%;
  margin-right: 4.05%;

  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.005em;


  color: #98A3A1;
}

.event-date-and-time {
  margin-top: 4.05%;
  margin-left: 4.05%;
  margin-right: 4.05%;
  margin-bottom: 4.05%;
}

.event-popup-date {
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.005em;

  color: #234240;
}

.event-popup-time {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: -4.5%;

  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.005em;

  color: #234240;
}
