.landing-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign-up-button {
  height: 3.25vw;
  width: 17.825vw;
  margin-top: 100px;
}