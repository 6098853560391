.event-bubble {
  height: 16vh;
  width: 100%;
  /* Version 1 / White */
  
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(35, 66, 64, 0.06);
  border-radius: 20px;
}

.event-name {
  margin-left: 5.3%;
  margin-top: 1.5vh;

  /* Heading 4 */

  font-family: Roboto-Medium;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.005em;

  /* Version 1 / Black */

  color: #234240;
}

.event-date {
  margin-left: 5.3%;
  margin-top: 0.3vh;

  /* Medium Text */

  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.005em;

  /* Version 1 / Dark Gray */

  color: #98A3A1;
}

.event-bubble-edit-button {
  height: 3.5vh;
  width: 3.5vh;

  margin-left: 84.649%;
  margin-bottom: 2vh;
  /* margin-top: 0.16%;
  margin-left: 8.25%; */
  
  cursor: pointer;
}

.enroll-button {
  background: #F0F7F6;
  border-radius: 0px 0px 20px 20px;

  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.005em;
  border-color: transparent;
  cursor: pointer;

  font-family: Roboto-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.005em;

  width: 100%;
  height: 100px;
}

.enroll-button:focus {
    outline: none !important;
    outline-offset: none !important;
}
