.events-list-container {
  display: flex;
  justify-content: left;
  align-items: top;

  background-color: #FFF8F7;
  border-color: transparent;
  min-height: 54vh;
  width: 100vw;
}

.events-list-title {
  background-color: #FFF8F7;

  margin-left: 6.9444%;
  margin-top: 3.1667%;

  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: 1000;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.015em;

  /* Version 1 / Black */

  color: #234240;
}

.events-list-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.6vh;
  grid-auto-rows: 16vh;

  /* justify-content: right;
  align-items: right; */
  width: 87.4278vw;

  margin-top: 1.7875vw;
  margin-left: 6.9444%; 

  border-color: black;
  /* border-style: solid; */
  border-width: 1px;
}

.no-upcoming-livestreams-text {

  /* Medium Text */

  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.005em;

  /* Version 1 / Dark Gray */

  color: #98A3A1;
}