.navbar-container {
    background-color: white;
    height: 8.565vh;
    width: 100vw;

    /* Version 1 / White */
    box-shadow: 0px 1.8px 0px rgba(35, 66, 64, 0.1);
}

.home-button {
    position: absolute;
    width: 5%;
    height: 100%;
    left: 5vw;
    top: 0px;
    background-color: rgba(52, 52, 52, 0.0);
    border: none;
    text-align: center;

    /* Heading 1 */
    font-family: Roboto-Bold;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;

    /* identical to box height */
    letter-spacing: 0.02em;

    /* Version 1 / Primary Color */

    color: #12908E;
}

.home-button:focus {
    outline: none !important;
    outline-offset: none !important;
}

.explore-button {
    position: absolute;
    width: 4.8%;
    height: 100%;
    left: 12.569vw;
    top: 0px;
    background-color: rgba(52, 52, 52, 0.0);
    border: none;
    text-align: center;

    /* Large Text */
    
    font-family: Roboto-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */
    
    letter-spacing: 0.01em;
    
    /* Version 1 / Black */
    
    color: #234240;
}

.pricing-button {
    position: absolute;
    width: 4.6%;
    height: 100%;
    left: 20.07vw;
    top: 0px;
    background-color: rgba(52, 52, 52, 0.0);
    border: none;
    text-align: center;
    
    /* Large Text */
    
    font-family: Roboto-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */
    
    letter-spacing: 0.01em;
    
    /* Version 1 / Black */
    
    color: #234240;
}

.teach-button {
    position: absolute;
    width: 4.05%;
    height: 100%;
    left: 27.292vw;
    top: 0px;
    background-color: rgba(52, 52, 52, 0.0);
    border: none;
    text-align: center;
    
    /* Large Text */
    
    font-family: Roboto-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */
    
    letter-spacing: 0.01em;
    
    /* Version 1 / Black */
    
    color: #234240;
}

.log-in-button {
    position: absolute;
    width: 5.35%;
    height: 100%;
    left: 90.5556vw;
    top: 0px;
    background-color: rgba(52, 52, 52, 0.0);
    border: none;
    text-align: center;
    
    /* Large Text */
    
    font-family: Roboto-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */
    
    letter-spacing: 0.01em;
    
    /* Version 1 / Primary Color */
    
    color: #12908E;
}
