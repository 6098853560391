.streamer-bio-container {
  border-color: black;
  /* border-style: solid; */
  border-width: 1px;
  height: 14.30vw;
  width: 69.2778vw;
  margin-left: 50px;
}

.streamer-bio-header {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 1.7875vw;

  height: 10%;
  width: 30%; 
}

.streamer-name {  
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: 1000;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.015em;
  
  
  color: #234240;
}

.streamer-bio-edit-button {
  height: 3.5vh;
  width: 3.5vh;

  margin-top: 0.16%;
  margin-left: 8.25%;
  
  cursor: pointer;
}

.streamer-occupation {
  height: 1.43vw;
  width: 65.2778vw;

  margin-top: 0.8342vw;
    
  /* Medium Text */
  
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  
  letter-spacing: 0.005em;
  
  /* Version 1 / Dark Gray */
  
  color: #98A3A1;
}

.streamer-description {
  width: 69.2778vw;
 
  margin-top: 0.8342vw;
  
  /* Medium Text */
  
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  
  letter-spacing: 0.005em;
  
  /* Version 1 / Black */
  
  color: #234240;
}

.streamer-link {
  width: 166px;
  height: 24px;
  left: 400px;
  top: 312px;

  margin-top: 0.8342vw;
  
  /* Medium Text */
  
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  
  letter-spacing: 0.005em;
  
  /* Version 1 / Primary Color */
  
  color: #12908E;
}