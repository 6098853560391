.purchase-complete-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #FFFFFF;
}

.purchase-complete-checkmark {
  height: 5.94vh;
  width: 5.94vh;
  margin-top: 5.94vh;
}

.thank-you-text {
  margin-top: 1.386vh;
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;

  text-align: center;
  letter-spacing: 0.015em;

  color: #234240;
}

.order-confirmation-text {
  margin-top: 1.386vh;

  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.005em;

  color: #98A3A1;
}

.order-details-title-text {
  margin-top: 5.4vh;

  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;

  letter-spacing: 0.01em;

  color: #234240;
}
.order-details-box {
  display: flex;
  flex-direction: row;

  height: 32.71vh;
  width: 62.55vw;
  margin-top: 2.7vh;
  background: #FFFFFF;

  border: 1.5px solid #E3ECEB;
  border-radius: 30px;
}

.order-details-container {
  display: flex;
  flex-direction: column;

  margin-top: 2.7vh;
  margin-left: 2.7vh;
  width: 50%;
}
.order-details-header-text {
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;

  letter-spacing: 0.01em;

  color: #98A3A1;
}

.order-details-standard-text {
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.005em;

  color: #234240;
}

.order-details-cost-line {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1.35vh;
  margin-right: 3vh;

}

.purchase-complete-divider {
  height: 2px;
  margin-top: 1.35vh;
  margin-right: 3vh;
  background: #E3ECEB;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.order-details-total-cost-text{
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: 1000;
  font-size: 20px;
  line-height: 24px;

  letter-spacing: 0.01em;

  color: #234240;
}

.order-details-total-cost-price {
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: 1000;
  font-size: 20px;
  line-height: 24px;

  text-align: right;
  letter-spacing: 0.01em;

  color: #12908E;
}