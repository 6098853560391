.checkout-page-container {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
}

.checkout-title {
  margin-top: 4%;
  
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;

  text-align: center;
  letter-spacing: 0.015em;

  color: #234240;
}

.checkout-info-container {
  display: flex;
  flex-direction: row;

  margin-top: 2%;
  margin-left: 9.33%;
  margin-right: 9.33%;
}

.customer-info-text-fields {
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 43.456vw;
}

.checkout-order-summary {
  display: flex;
  flex-direction: column;

  height: 40.55vh;
  width: 42.55%;
  margin-left: 4%;

  background: #FFFFFF;

  border: 1.5px solid #E3ECEB;
  box-sizing: border-box;
  border-radius: 20px;
}

.order-summary-title {
  margin-top: 5.25%;
  margin-left: 6.25%;
  color: #234240;

  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;

  letter-spacing: 0.01em;
}

.order-sum-cost-line {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  
  margin-right: 6.25%;
  margin-left: 6.25%;
}

.order-sum-total-cost-line {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  margin-right: 6.25%;
  margin-left: 6.25%;
}

.order-sum-cost-name {
  width: 25.5vw;

  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.005em;

  color: #234240;
}

.order-sum-cost {
  width: 5vw;

  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  text-align: right;
  letter-spacing: 0.005em;

  color: #234240;
}

.order-sum-total-text {
  width: 27vw;

  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: 1000;
  font-size: 22px;
  line-height: 24px;
  
  letter-spacing: 0.01em;

  color: #234240;
}

.order-sum-total-cost {
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: 1000;
  font-size: 22px;
  line-height: 24px;
  
  text-align: right;
  letter-spacing: 0.01em;
    
  color: #12908E;
}

.order-sum-divider {
  height: 2px;
  margin-top: 4%;
  margin-right: 6.25%;
  margin-left: 6.25%;

  background: #E3ECEB;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.order-sum-divider-bold {
  height: 2px;
  margin-top: 4%;
  margin-right: 6.25%;
  margin-left: 6.25%;

  background: #D2DCDB;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.order-sum-pay-button {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 15.47%;
  margin-top: 4%;
  margin-right: 6.25%;
  margin-left: 6.25%;

  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: 1000;
  font-size: 20px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.01em;

  border-radius: 100px;
}

.customer-info-module {
  height: 16.634vh;
  margin-bottom: 7.92vh;
}

.customer-info-title {
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;

  letter-spacing: 0.01em;

  color: #000000;
}

.customer-info-form {
  height: 10.89vh;
  margin-top: 4%;

  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.checkout-text-field {
  height: 5.12vh;
  width: 20vw;
  margin-left: 3.23%;

  border: none;
  border-radius: 10px;
  

  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.005em;

  color: #98A3A1;
}

.checkout-text-field:focus {
  outline: none !important;
  outline-offset: none !important;
}

.checkout-text-field::placeholder {
  color: #98A3A1;
}

.customer-info-module-divider-horizontal {
  background: #E3ECEB;
  height: 0.185vh;
}

.customer-info-module-divider-vertical {
  background: #E3ECEB;
  height: 2.56vh;
  width: 0.285vh;


  margin-left: 3.2258%;
  margin-right: 3.2258%;
}

.payment-info-module {
  height: 100px;
}

.payment-info-form {
  height: 16.435vh;
  margin-top: 4%;

  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.credit-card-number-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-right: 3.23%;
}

.credit-card-icon-container {
  display: flex;
  flex-direction: row;
  width: 48.74%;

  justify-content: flex-end;
}

.credit-card-icon {
  height: 2.56vh;
  margin-left: 3.5%;
}

.payment-form-bottom-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

